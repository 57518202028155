import React, { useState } from 'react';
import PoliciesHandlerComponent from './PoliciesHandlerComponent/PoliciesHandlerComponent';

import './Footer.css';

/**
 * Footer component
 */
const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [content, setContent] = useState(null);

  /**
   * To set content state to null
   */
   const closeContentHandler = () => {
    setContent(null);
  };

  return (
    <>
      <div className="footer">
        <div className="d-flex no-padding container">
          <p>
            ©
            {currentYear}
            {' '}
            WSE Hong Kong Limited
          </p>
          <p className="pointer" onClick={() => { return setContent('privacy'); }}>| Privacy Policy</p>
          <p className="pointer" onClick={() => { return setContent('cookie'); }}>| Cookie Policy</p>
          <p className="pointer" onClick={() => { return setContent('terms-of-use'); }}>| Terms Of Use</p>
        </div>
      </div>
      {content
        && (
          <PoliciesHandlerComponent
            content={content}
            closeContentHandler={closeContentHandler}
          />
        )}
    </>
  );
};

export default Footer;
