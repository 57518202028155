import { useState, useEffect } from 'react';
import WseLogo from '../../assets/images/logo-white-big.png';
import './Header.css';

/**
 * Header component
 */
const Header = () => {
  /*const domainName =
    window.location.hostname.split('.')[0].trim() === undefined ||
    'localhost'
      ? 'wipro'
      : window.location.hostname.split('.')[0].trim();*/
  const domainName = window.location.hostname.split('.')[0].trim()
  const [companyLogo, setCompanyLogo] = useState(null);

  const loadImage = async () => {
    const { default: image } = await import(
      `../../assets/images/${domainName.toUpperCase()}-Logo.png`
    );
    setCompanyLogo(image);
  };

  useEffect(() => {
    loadImage().catch((error) => console.log(error));
  }, []);

  const styles = {
    width: domainName === 'wipro' ? '80px' : '150px'
  };

  return (
    <div className="nav">
      <div className="d-flex no-padding container">
        <div className="nav-menu-left">
          <img className="wse-logo" src={WseLogo} alt="WseLogo" />
        </div>
        <div className="nav-menu-right">
          {companyLogo && (
            <img
              className="enterprise-logo"
              src={companyLogo}
              style={styles}
              alt="Enterprise"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;