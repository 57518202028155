/**
 * To call rest api with default header
 * @param {string} type
 * @param {string} url
 * @param {...object} rest
 */
 export const apiRequestWithDefaultHeader = (type, url, rest) => {
  return fetch(url, {
    method: type,
    ...rest
  });
};