import { apiRequestWithDefaultHeader } from '../../helpers/apiRequest/apiRequest';

/**
 * To register prospect users
 * @param {string} url
 * @param {string} body
 * @returns {Promise<object>}
 */
 const prospectRegister = (url, body) => {
  return apiRequestWithDefaultHeader('POST', url, {
    body,
    headers: {
      'Content-Type': 'application/json',
      'x-territory-key': process.env.REACT_APP_TERRITORY_KEY,
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_KEY
    }
  })
    .then((response) => {
      return response;
    });
};

export default prospectRegister;
