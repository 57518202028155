import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { LinearProgress, Typography, CircularProgress } from '@mui/material';
import beginner from '../../assets/images/svg/prospect-beginner.svg';
import intermediate from '../../assets/images/svg/prospect-intermediate.svg';
import pro from '../../assets/images/svg/prospect-pro.svg';
import prospectRegister from '../../services/ProspectRegister/ProspectRegister';
import getLoginTokenAPIM from '../../services/LoginToken/LoginToken';
import enterprises from '../../constants/enterprises';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const theme = createTheme({
  palette: {
    primary: {
      main: '#003359'
    }
  },
  typography: {
    fontFamily: [
      'ProximaNova',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});

export default function ProspectRegistration() {
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [progress, setProgress] = useState(5);
  const startTime = useRef();
  const [centerNameIndex, setCenterNameIndex] = useState(0);

  const autoLoginHandler = (username, password) => {
    const idamTokenUrl = `${process.env.REACT_APP_IDAM_APIM_URL}connect/token`;
    const url = `${idamTokenUrl}?username=${encodeURIComponent(username)}&password=${password}&clientId=WSE-FrontEnd&clientSecret=bnNlLXBhc3N3b3Jk`;

    getLoginTokenAPIM(url).then(async (res) => {
      const data = await res.json();
      if (res.status === 200) {
        setProgress(100);
        setTimeout(() => {
          const placementLoginUrl = `${process.env.REACT_APP_NSE_URL}placementLogin/${data.access_token}`;
          redirectToCoreCourse(placementLoginUrl);
       }, 500);
      }
      else {
        if((new Date().getTime() - startTime.current) > 90000) {
          setIsLoading(false);
          setProgress(0);
        } else {
          retryCall(username, password);
        }
      }
    })
    .catch((err) => {
      setIsLoading(false);
      setProgress(5);
    });
  }

  //let timeoutIDAMCall = null;
  const retryCall = (userName, password) => {
    setIsLoading(true);
    const randomNumber = Math.floor(Math.random() * (10 - 5 + 1) + 5);
    setProgress((prevProgress) => prevProgress + randomNumber);
    autoLoginHandler(userName, password);
  }

  const getUrlDomainName = () => {
    //return ( window.location.hostname.split('.')[0].trim() === undefined || 'localhost' ) ? "wipro" : window.location.hostname.split('.')[0].trim()
    return window.location.hostname.split('.')[0].trim()
  }

  const getCenterDetails = () => {
    const enterprise_name = getUrlDomainName();
    return enterprises[enterprise_name].centers;
  }

  const handleProspectSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const {firstName, lastName, username} = Object.fromEntries(data.entries());
    const userName = `wsetest_${username}`;
    setIsRegistering(true);
  
    const enterprise_name = getUrlDomainName();
    const center = enterprises[enterprise_name].centers[centerNameIndex];
    const { centerID, centerRefId } = center;
    
    const url = `${process.env.REACT_APP_LS_APIM_URL}crm/centers/${centerRefId}/prospects?centerid=${centerID}`;
    const postData = JSON.stringify({
      centerId: centerID,
      firstName: firstName,
      lastName: lastName,
      password: 'Pass@1234',
      email: userName,
      showplacementresultflag: true,
      trial: {
        enabled: false,
        onboardingEnabled: false,
        startDate: null,
        endDate: null,
        unitNumber: null,
      }
    });
    
    try {
      const response = await prospectRegister(url, postData);
      if (response && (response.status === 200 || response.status === 500)) {
        startTime.current = new Date().getTime();
        retryCall(userName, 'Pass@1234');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsRegistering(false);
    }
  };
  

  function redirectToCoreCourse(placementLoginUrl) {
    window.location.replace(placementLoginUrl); //redirect to external URL
    return null;
  }

  const handleChange = (event) => {
    setCenterNameIndex(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            backgroundColor: '#003359',
            color: '#fff',
            display: 'flex',
            marginTop: 8,
            justifyContent: 'space-evenly'
          }}
        >
          <Box
            sx={{
              backgroundColor: '#003359',
              color: '#fff',
              width:400,
              margin: 4
            }}
          >
            <h1 style={{
              width: '310px',
              marginLeft: 'auto',
              marginRight: 'auto'
            }}>Welcome to your Wall Street English Course. Let's discover your start level.</h1>
          </Box>
          <Box
            component="form"
            onSubmit={handleProspectSubmit}
            sx={{
              backgroundColor: '#fff',
              width: 450,
              margin: 4,
              padding:4,
              borderRadius: '4px',
              display: !isLoading ? 'block' : 'none'
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={16}>
                <h3
                  style={{
                    color: '#003359',
                    marginTop: 0
                  }}
                >
                  Complete the form to start the test
                </h3>
              </Grid>
              <Grid item xs={16}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={16}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  inputProps={{ maxLength: 100 }}
                />
              </Grid>
              <Grid item xs={16}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Email Address"
                  name="username"
                  autoComplete="username"
                  inputProps={{ maxLength: 64, pattern: "^[a-z0-9][-a-z0-9.+_]+@([-a-z0-9]+\.)+[a-z]{2,5}$" }}
                />
              </Grid>
              {
                (getUrlDomainName() === 'hcl') && (
                    <Grid item xs={16}>
                    <FormControl required sx={{ minWidth: 385 }}>
                      <InputLabel id="centerName-select-label">Center Name</InputLabel>
                      <Select
                        labelId="centerName-select-label" id="centerName-select"
                        value={centerNameIndex} label="Center Name" onChange={handleChange}
                      >
                        {getCenterDetails().map((center, index) => <MenuItem value={index} key={center.centerRefId} >{center.centerName}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                )
              }  
            </Grid>
            <Button
              type="submit"
              disabled={isRegistering}
              variant="contained"
              fullWidth
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: '#f12c3e',
                fontWeight: 600,
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#f12c3e"
                }
              }}
            >
              {isRegistering ? <CircularProgress color='primary' size="24px" /> : 'Send'}
            </Button>
          </Box>
          <Box
            sx={{
              backgroundColor:'#fff',
              width: 450,
              margin: 4,
              padding: 4,
              fontWeight: 600,
              borderRadius: '4px',
              display: isLoading ? 'block' : 'none'
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={16}>
                <p
                  style={{
                    color: '#003359',
                    margin: '0 auto',
                    lineHeight: '1.1'
                  }}
                >
                  Thank you for registering. There are three tests, please choose the best test for you.
                </p>
              </Grid>
              <Grid item xs={16}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '0 auto',
                    border: '5px solid #003359',
                    width: '360px',
                    height: '183px',
                    padding: '0 16px'
                  }}
                >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly'
                  }}>
                    <img
                      style={{
                        width: '60px',
                        height: '60px'
                      }}
                      src={beginner}
                      alt="beginner"
                    />
                    <span
                      style={{
                        color: '#003359',
                        lineHeight: '1.1',
                        fontStyle: 'italic',
                        textAlign: 'center'
                      }}
                    >
                      I know the basics
                    </span>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly'
                  }}>
                    <img
                      style={{
                        width: '60px',
                        height: '60px'
                      }}
                      src={intermediate}
                      alt="intermediate"
                    />
                    <span
                      style={{
                        color: '#003359',
                        lineHeight: '1.1',
                        fontStyle: 'italic',
                        textAlign: 'center'
                      }}
                    >
                      I can have a conversation
                    </span>
                  </div>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly'
                  }}>
                    <img
                      style={{
                        width: '60px',
                        height: '60px'
                      }}
                      src={pro}
                      alt="pro"
                    />
                    <span
                      style={{
                        color: '#003359',
                        lineHeight: '1.1',
                        fontStyle: 'italic',
                        textAlign: 'center'
                      }}
                    >
                      I have an advanced level
                    </span>
                  </div>
                </div>
              </Grid>
              <Box
                sx={{
                  width: '100%',
                  paddingTop: '24px',
                  paddingLeft: '16px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      mr: 1,
                      color: '#f12c3e'
                    }}
                  >
                    <LinearProgress
                      color='inherit'
                      variant="determinate"
                      sx={{
                        height: '30px',
                        border: '2px solid #f12c3e',
                        borderRadius: '2px',
                        "&.MuiLinearProgress-root::before": {
                          bgcolor: "#ffffff"
                        }
                      }}
                      value={progress}
                    />
                  </Box>
                  <Box
                    sx={{
                      minWidth: 35,
                      position: 'absolute',
                      left: '50%'
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 700,
                        color: '#003359'
                      }}
                    >
                      {`${Math.round(progress)}%`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}