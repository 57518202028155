const enterprises = {
  "tcs": {
    centers: [{
      "centerID": "afab2870-49b7-41df-87aa-72eaa5b6e63b",
      "centerRefId": "wseitcs01"
    }]
  },
  "hcl": {
    centers: [{
      "centerID": "d11518c7-7ab1-4c6f-80f0-187c3d577267",
      "centerRefId": "WSI99hcl01",
      "centerName": "HCL - India"
    },
    {
      "centerID": "116ac05e-926d-4127-acff-a7b39c652f04",
      "centerRefId": "WSI99hcl02",
      "centerName": "HCL - Vietnam"
    },
    {
      "centerID": "d83ffc29-f9c0-495f-a1cd-65a93ecd1f72",
      "centerRefId": "WSEIhcl03",
      "centerName": "HCL - Spain"
    }]
  },
  "wipro": {
    centers: [{
      "centerID": "02FDF447-525B-4FA3-8DF2-D5AC225C8E1B",
      "centerRefId": "WSEIwpo01"
    }]
  }
};

export default enterprises;