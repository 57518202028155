import { apiRequestWithDefaultHeader } from '../../helpers/apiRequest/apiRequest';

/**
 * To get IDAM login token via APIM
 * @param {string} url
 * @returns {Promise<object>}
 */
const getLoginTokenAPIM = (url) => {
  return apiRequestWithDefaultHeader('POST', url, {
    headers: {
      'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_KEY
    }
  })
    .then((response) => {
      return response;
    });
};

export default getLoginTokenAPIM;
